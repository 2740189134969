import React from 'react';
import {
  Container,
  Row,
  Col,
  Image
} from 'react-bootstrap';

export default function CaseStudies() {
  return( 
    <Container>
      <Row>
        <Col xs={{span: 10, offset: 1}}>
          <h3>Case Studies</h3>
          <hr></hr>
        </Col>
      </Row>
      <Row>
        <Col xs={{span: 2, offset: 1}}>
          <Image
            width={100} height={100} className="clientLogo"
            src="/images/clients/auspost.png" alt="Australia Post" title="Australia Post">
          </Image>  
        </Col>
      </Row>
      <Row>
        <Col xs={{span: 10, offset: 1}}>
          <p>
          SecurePay is a subsidiary of Australia Post and provides various payment services. We worked as Lead API Developers on an expansion of their POLi Payments system. This new feature enables customers to pay for purchases in real-time using National Payment Platform (NPP) Pay IDs. The solution is built using a serverless micro-services architecture and mixes APIs and asynchronous queueing. Technologies used are .NET 6, AWS Lambda, DynamoDB, SQS, CodePipeline, X-Ray and Splunk.
          </p>
          <hr></hr>
        </Col>
      </Row>
      <Row>
        <Col xs={{span: 2, offset: 1}}>
          <Image
            width={100} height={100} className="clientLogo"
            src="/images/clients/nab.png" alt="NAB" title="National Australia Bank">
          </Image>  
        </Col>
      </Row>
      <Row>
        <Col xs={{span: 10, offset: 1}}>
          <p>
          NAB's Treasury Risk team maintains systems for collating and analysing complex market data. We worked on their Modernisation and Migration project to uplift their tech stack and migrate their apps onto Azure. Technologies used were .NET, Azure and DevOps.
          </p>
          <hr></hr>
        </Col>
      </Row>
      <Row>
        <Col xs={{span: 2, offset: 1}}>
          <Image
            width={100} height={100} className="clientLogo"
            src="/images/clients/vicroads.png" alt="VicRoads" title="VicRoads">
          </Image>  
        </Col>
      </Row>
      <Row>
        <Col xs={{span: 10, offset: 1}}>
          <p>
          VicRoads is a Victorian Government Department. The project needed our .NET and AWS expertise to uplift their Cloud and DevOps capabilities. Our main achievement was to re-architect an API for the Drivers Learner Kit initiative. We also took the reins for an expansion of the Drink Drug Drive program and worked on various DevOps initiatives.
          </p>
          <hr></hr>
        </Col>
      </Row>
      <Row>
        <Col xs={{span: 2, offset: 1}}>
          <Image
            width={100} height={100} className="clientLogo"
            src="/images/clients/beteasy.png" alt="BetEasy" title="BetEasy">
          </Image>  
        </Col>
      </Row>
      <Row>
        <Col xs={{span: 10, offset: 1}}>
          <p>
          BetEasy (now SportsBet) was an online wagering company. We worked as Senior .NET Developers in their Digital team where we led new initiatives such as Credit Card Tokenisation, Racing BlackBook and Social Login. Prior to this we worked in their Core Platform team to re-engineer their Settlement engine making it capable of handling Melbourne Cup volumes with ease. Technologies used were .NET, AWS and DevOps.
          </p>
        </Col>
      </Row>
    </Container>
  );
}