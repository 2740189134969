import React from 'react';
import {Routes, Route} from 'react-router-dom';
import About from './About';
import CaseStudies from './CaseStudies';
import Contact from './Contact';
import NotFound from './NotFound';

export default function AppRoutes() {
    return (
      <Routes>
        <Route exact path="/" element={<About/>} />
        <Route path="/casestudies" element={<CaseStudies />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<NotFound/>}/>
      </Routes>
    );
  }
