import React from 'react';
import {
  Container,
  Row,
  Col
} from 'react-bootstrap';

export default function Contact() {

  return(
    <Container>
      <Row>
        <Col xs={{span: 10, offset: 1}}>
          <h3>Contact</h3>
          <hr></hr>
        </Col>
      </Row>
      <Row>
        <Col xs={{span: 10, offset: 1}}>
          <p>
            The CodeBlitz HQ is based in Melbourne, Australia.
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={{span: 10, offset: 1}}>
          <p>
          If you'd like to find out more then please <a href="mailto:blair@codeblitz.io">email</a> us.
          </p>
        </Col> 
      </Row>
      <Row>
        <Col xs={{span: 10, offset: 1}}>
          <p>
          You can also reach out via <a href="https://www.linkedin.com/in/blairmorris">LinkedIn</a>.
          </p>
        </Col> 
      </Row>
    </Container>
  );
}