import React from 'react';
import {
  Container,
  Row,
  Col,
  Image,
  Table
} from 'react-bootstrap';
import {Link} from 'react-router-dom';

export default function About() {
  return(
    <Container>
      <Row>
        <Col xs={{span: 10, offset: 1}}>
          <h3>About</h3>
          <hr/>
        </Col>
      </Row>
      <Row>
        <Col xs={{span: 10, offset: 1}}>
          <p>
          CodeBlitz is a software engineering startup with a proven record of delivery.
          Our team comprises Microsoft and AWS certified architects and developers.
          We've worked across a range of industries including finance, wagering and government.
          We're adept in Agile and Devops practices and can cross function in several areas.
          We're also well regarded by our clients for our strong collaboration and mentoring capabilities.
          </p>
        </Col>
      </Row>
      <Row >
        <Col md={{span: 2, offset: 4}} sm={{span: 3, offset: 3}} xs={{span: 3, offset: 2}}>
          <Image
            width={100} height={100} className="certLogo"
            src="/images/certs/mcsd.png" alt="MCSD" title="Microsoft Certified Solution Developer">
          </Image>
        </Col>
        <Col md={{span: 2, offset: 0}} sm={{span: 3, offset: 1}} xs={{span: 3, offset: 1}}>  
          <Image
            width={100} height={100} className="certLogo"
            src="/images/certs/aws.png" alt="AWS" title="AWS Certified Solutions Architect">
          </Image>
        </Col>
      </Row>
      <Row>
        <Col xs={{span: 10, offset: 1}}>
          <br/>
          <hr></hr>
          <p>
            Here are some of the technologies we work with
          </p>
          <Table striped>
            <thead>
              <tr>
                <th>Technical Expertise</th>
                <th></th>
                <th className="text-right">Industries</th>
              </tr>
              </thead>
            <tbody>
              <tr>
                <td>.NET, .NET Core</td>
                <td>AWS</td>
                <td className="text-right">Finance</td>
              </tr>
              <tr>
                <td>C#, Python, Node</td>
                <td>Azure</td>
                <td className="text-right">Wagering</td>
              </tr>
              <tr>
                <td>React, Javascript</td>
                <td>Agile</td>
                <td className="text-right">Government</td>
              </tr>
              <tr>
                <td>APIs, Microservices, Queues</td>
                <td>Devops</td>
                <td className="text-right">Consulting</td>
              </tr>
              <tr>
                <td>SQL Server, Postgres, NoSQL</td>
                <td>Solutions Architecture</td>
                <td className="text-right">Vendors</td>
              </tr>
            </tbody>
          </Table>
          </Col> 
      </Row>
      <Row>
      </Row>
      <Row>
        <Col xs={{span: 10, offset: 1}}>
          <br/>
          <hr/>
          <p>
            And here are a few of our clients, see <Link to="/casestudies" onClick={() => {window.scroll(0, 0);}}>case studies</Link>.
          </p>
        </Col> 
      </Row>
      <Row>
        <Col md={{span: 2, offset: 2}} xs={{span: 4, offset: 2}}>
          <Image
            width={100} height={100} className="clientLogo"
            src="/images/clients/nab.png" alt="NAB" title="National Australia Bank">
          </Image>
        </Col>
        <Col md={{span: 2, offset: 0}} xs={{span: 4, offset: 0}}>
          <Image
            width={100} height={100} className="clientLogo"
            src="/images/clients/beteasy.png" alt="BetEasy" title="BetEasy">
          </Image>
        </Col>
        <Col md={{span: 2, offset: 0}} xs={{span: 4, offset: 2}}>
          <Image
            width={100} height={100} className="clientLogo"
            src="/images/clients/vicroads.png" alt="VicRoads" title="VicRoads">
          </Image>
        </Col>
        <Col md={{span: 2, offset: 0}} xs={{span: 4, offset: 0}}>
          <Image
            width={100} height={100} className="clientLogo"
            src="/images/clients/auspost.png" alt="Australia Post" title="Australia Post">
          </Image>
        </Col>
      </Row>
    </Container>
  );
}