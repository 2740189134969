import React from 'react';
import {useLocation} from 'react-router-dom';
import {
  Container,
  Row,
  Col
} from 'react-bootstrap';

export default function NotFound() {
  let location = useLocation();

  return (
    <Container>
      <Row>
        <Col xs={{span: 10, offset: 1}}>
          <h3>404 Not Found</h3>
          <hr></hr>
        </Col>
      </Row>
      <Row>
        <Col xs={{span: 10, offset: 1}}>
          <p>
            Oops, the resource <code>{location.pathname}</code> could not be found.
          </p>
        </Col>
      </Row>
    </Container>
  )
}