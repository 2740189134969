import React from 'react';
import {Link} from 'react-router-dom';
import {Nav, Navbar} from 'react-bootstrap';
import Container from 'react-bootstrap/Container';

export default function Header() {
  return (
    <Navbar bg="dark" data-bs-theme="dark" expand="md">
      <Container>
        <Navbar.Brand as={Link} to="/">CodeBlitz</Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse >
          <Nav className="ms-auto">
            <Nav.Item>
              <Nav.Link as={Link} to="/">About</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="/casestudies">Case Studies</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="/contact">Contact</Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
