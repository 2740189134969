import {React} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import Header from './Header';
import AppRoutes from './AppRoutes';
import './App.css';

export default function App() {
  return (
    <Router>
        <Header/>
        <div className="appRoute">
          <AppRoutes/>
        </div>
    </Router>
  );
}
